import { validate as uuid_validate } from 'uuid';


export default {
    /**
     * Rule validate required
     * @param {any} v the given value to validate
     * @param {string} label
     * @returns validate
     */
    ruleRequired: (v, label = null) => {
        return !!v || `The ${label ?? 'field'} is required`;
    },

    /**
     * Rule validate required object on a given property
     * @param {any} v the given value to validate
     * @param {string} property the given property of the object to check for
     * @param {string} label
     * @returns validate
     */
    ruleRequiredProperty: (v, property = "id", label = null) => {
        return (!!v && !!v[property]) || `The ${label ?? 'field'} is required`;
    },

    /**
     * Rule validate min length
     * @param {any} v the given value to validate
     * @param {number} length min length to check
     * @param {string} label
     * @returns validate
     * @example
     * ruleMinLength([1,2,3], 2, 'array') // The array minimum length is 2
     * ruleMinLength("abc", 2, 'string') // The string minimum length is 2
     */
    ruleMinLength: (v, length, label = null) => {
        let error = `The ${label ?? 'field'} must be at least ${length} character${length === 1 ? '' : 's'} long`
        if (Array.isArray(v)) {
            error = `At least ${length} ${label ?? 'item'}${length === 1 ? '' : 's'} is required`
        }

        return (
            v.length >= length ||
            error
        );
    },
    /**
     * Rule validate max length
     * @param {any} v the given value to validate
     * @param {number} length max length to check
     * @param {string} label
     * @returns validate
     */
    ruleMaxLength: (v, length, label = null) => {
        let error = `The ${label ?? 'field'} can be at most ${length} character${length === 1 ? '' : 's'} long`
        if (Array.isArray(v)) {
            error = `At most ${length} ${label ?? 'item'}${length === 1 ? '' : 's'} are allowed`
        }

        return (
            v.length <= length ||
            error
        );
    },
    /**
     * Rule validate email
     * @param {any} v the given value to validate
     * @param {string} label
     * @returns validate
     */
    ruleEmail: (v, label = null) => {
        return (
            !v ||
            /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+(\.\w{2,63})+$/.test(String(v)) ||
            `The ${label ?? 'field'} must be a valid email`
        );
    },

    /**
     * Rule validate email username
     * @param {any} v the given value to validate
     * @param {string} label
     * @returns validate
     * @example
     */
    ruleEmailUserName: (v, label = null) => {
        return (
            !v ||
            /^[a-zA-Z0-9-_.]+$/.test(String(v)) ||
            `The ${label ?? 'field'} must be a valid email username`
        );
    },


    /**
      * Rule validate integer number
      * @param {any} v the given value to validate
      * @param {string} label
      * @returns validate
      */
    ruleInteger: (v, label = null) => {
        return (
            Number.isInteger(Number(v)) ||
            `The ${label ?? 'field'} must be a valid integer`
        );
    },

    /**
     * Rule validate number
     * @param {any} v the given value to validate
     * @param {string} label
     * @returns validate
     */
    ruleNumber: (v, label = null) => {
        return (
            !v || !isNaN(parseFloat(v)) || `The ${label ?? 'field'} must be a valid number`
        );
    },

    /**
     * Rule validate number less than
     * @param {any} v the given value to validate
     * @param {any} targetValue the target value to check againt
     * @param {string} label
     * @returns validate
     */
    ruleLessThan: (v, targetValue, label = null) => {
        return (
            !v ||
            !targetValue ||
            parseFloat(v) < parseFloat(targetValue) ||
            `The ${label ?? 'field'} must less than ${targetValue}`
        );
    },
    /**
      * Rule validate number greater than
      * @param {any} v the given value to validate
      * @param {any} targetValue the target value to check againt
      * @param {string} label
      * @returns validate
      */
    ruleGreaterThan: (v, targetValue, label = null) => {
        return (
            !v ||
            !targetValue ||
            parseFloat(v) > parseFloat(targetValue) ||
            `The ${label ?? 'field'} must greater than ${targetValue}`
        );
    },

    /**
     * Rule validate URL
     * @param {any} v the given value to validate
     * @param {string} label
     * @returns validate
     */
    ruleUrl: (v, label = null) => {
        return (
            !v ||
            /^(http|https):\/\/[^ "]+$/.test(String(v)) ||
            `The ${label ?? 'field'} must be a valid URL`
        );
    },

    /**
     * Rule validate UUID V4
     * @param {any} v the given value to validate
     * @param {string} label
     * @returns validate
     */
    ruleUUIDV4: (v, label = null) => {
        return (
            !v ||
            uuid_validate(v) ||
            `The ${label ?? 'field'} must be a valid UUID`
        );
    },

    /**
     * Rule validate subdomain
     * @param {any} v the given value to validate
     * @param {string} label
     * @returns validate
     */
    ruleSubDomain: (v, label = null) => {
        return (
            !v ||
            /^(?=.{1,63}$)[a-z0-9]+(-?[a-z0-9]+)*[a-z0-9]*$/.test(v) ||
            `The ${label ?? 'field'} can only contain lowercase alphanumeric characters and hyphens`
        );
    }
};
