<template>
    <div class="d-flex flex-column fill-height">
        <div style="flex-grow: 1"></div>
        <div class="grid-layout-column grid-basis-auto grid-gap-lg align-center">
            <v-img src="@/assets/ai-copilot.svg" :height="150" contain/>
            <div class="text-center">
                <div v-if="text" class="loading-ellipsis text-bold text-body-1 mb-1">{{ text }}</div>
                <div v-if="secondaryText" class="text-body-2">{{ secondaryText }}</div>
            </div>
            <v-sheet class="flex-grow-1" width="100%" :max-width="maxWidth" height="14">
                <v-progress-linear class="rounded-lg" color="primary" background-color="primary" background-opacity="0.14" indeterminate height="14" width="100%"/>
            </v-sheet>
        </div>
        <div style="flex-grow: 1"></div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: "",
        },
        secondaryText: {
            type: String,
            default: "",
        },
        maxWidth: {
            default: "380px"
        }
    }
}
</script>