<template>
    <v-sheet v-if="open && accessGrid.getNumApps()" class="d-flex flex-column cart" outlined rounded max-height="100%"
        height="calc(100vh - 319px)" max-width="400px" :elevation="floating ? 8 : 0">
        <div class="ma-4 d-flex justify-space-between align-center">
            <h6>Review your changes</h6>
            <a v-show="accessGrid.hasChanges" class="text-body-2" @click="() => accessGrid.clearChanges()">Clear</a>
            <v-btn icon @click="$emit('close')" height="20" width="20"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-divider />
        <div v-show="accessGrid.hasChanges">
            <AlertBanner type="info" rounded="0">
                <div class="d-flex text-body-2 align-center">
                    <div>Create tasks out of these, to remind me to make these changes</div>
                    <v-switch class="ma-0 pa-0 ml-2" dense hide-details
                        :input-value="accessGrid.createTasks"
                        @change="(value) => accessGrid.setCreateTasks(value)"
                    />
                </div>
            </AlertBanner>
            <v-divider />
        </div>
        <div :class="accessGrid.hasChanges ? 'flex-grow-1' : ''" style="overflow-y: auto;">
            <AccessGridCartSection :accessGrid="accessGrid" status="ADD" />
            <AccessGridCartSection :accessGrid="accessGrid" status="REMOVE" />
        </div>
        <div v-show="!accessGrid.hasChanges" class="grid-layout-column flex-grow-1 align-space-between ma-4 text-body-2 text-center">
            <v-spacer class="flex-grow-1" />
            <v-icon color="text-disabled" size="48">mdi-clipboard-check-outline</v-icon>
            <div :class="$vuetify.theme.dark ? 'text-disabled--text' : 'text-secondary--text'">You haven't made any changes. Click the checkboxes on the left to start
                changing access.</div>
            <v-spacer class="flex-grow-1" />
        </div>
        <v-divider />
        <div class="grid-layout-column">
            <v-btn class="ma-4" color="primary" :disabled="!accessGrid.hasChanges || accessGrid.loading" elevation="0"
                :loading="accessGrid.loading" @click="$emit('apply')">{{ applyTitle }}</v-btn>
        </div>
    </v-sheet>
</template>

<script>
import AccessGridCartSection from "./AccessGridCartSection.vue";
import AlertBanner from "@/components/AlertBanner.vue";

export default {
    components: {
        AccessGridCartSection,
        AlertBanner,
    },
    props: {
        accessGrid: {
            required: true,
        },
        floating: {
            type: Boolean,
            default: false,
        },
        open: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        applyTitle() {
            return `Apply (${this.accessGrid.getNumChanges()}) change${this.accessGrid.getNumChanges() != 1 ? 's' : ''}`
        },
    }
}
</script>

<style scoped lang="scss">
.floating-cart-container {
    position: relative;

    .cart {
        position: absolute;
        right: 0;
        z-index: 10;
    }
}
</style>