<template>
    <div>
        <div class="text-body-1 font-weight-bold">
            {{ title }}
        </div>
        <p v-if="description" class="text-body-2">
            {{ description }}
        </p>
    </div>
</template>


<script>
export default {
    name: "FormSectionDescription",

    props: {
        title: {
            type: String,
            default: "",
            required: true,
        },
        description: {
            type: String,
            default: "",
        },
    },
}
</script>