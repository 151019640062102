<template>
    <v-sheet v-if="applications.length" class="pa-2 grid-layout grid-basis-auto" rounded="lg" :color="color">
        <v-sheet style="padding: 2px;" color="white" rounded v-for="app in applications.slice(0, max || applications.length)" :key="app.id">
            <ApplicationIcon :application="app" :size="size" tooltip inverted/>
        </v-sheet>
        <v-sheet v-if="applications.length - max > 0" style="padding: 2px;" color="white" rounded>
            <v-sheet class="d-flex align-center black--text" color="white" :min-width="size" :height="size" rounded>+{{ applications.length - max }}</v-sheet>
        </v-sheet>
    </v-sheet>
</template>

<script>
import ApplicationIcon from '@/components/ApplicationIcon.vue';

export default {
    components: { ApplicationIcon },
    props: {
        applications: {
            type: Array,
            required: true,
        },
        color: {
            type: String,
            default: "structure",
        },
        max: {
            type: Number,
            default: 0,
        },
        size: {
            type: Number,
            default: 24,
        }
    }
}
</script>