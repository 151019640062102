<template>
    <div>
        <template>
            <v-system-bar v-if="shouldShowTrialBar"
                          class="grid-layout grid-gap-lg grid-basis-auto align-content-center align-center justify-center black--text text-bold"
                          app :color="plan.status === 'trialing' ? '#D1F8FD' : '#FFEEB5'" height="40">

                <!-- Trialing -->
                <div v-if="plan.status === 'trialing' && !checkFeatureFlag('lighthouse-customer')">
                    <span>You've got {{ calcTrialDaysRemaining(plan) }} days left on your trial</span>
                    <SubscribeButton class="ml-2" to="/manage/billing/purchase" rounded height="28"/>
                    <v-icon size="24" @click="visible = false" style="position: fixed; right: 0;">mdi-close</v-icon>
                </div>

                <!-- Payment Failed -->
                <div v-else-if="plan.status === 'past_due'">
                    <span class="align-center">Payment failed, please update your <a href="/manage/billing">billing information</a></span>
                </div>

                <!-- Trial Expired -->
                <div v-else-if="plan.status === 'paused'">
                    Your payment is required
                    <SubscribeButton class="ml-2" to="/manage/billing/purchase" rounded height="28"/>
                </div>


                <!-- Our subscription settings should be set to leave the subscription in an unpaid state if
                     payment fails, so these four states should never occur, but are here just in case do, see:
                     https://docs.stripe.com/billing/subscriptions/overview
                 -->

                <!-- canceled -->
                <div v-else-if="plan.status === 'canceled'">
                    Your subscription has been canceled, please contact support
                </div>

                <!-- unpaid -->
                <div v-else-if="plan.status === 'unpaid'">
                    Your subscription is unpaid, please contact support
                </div>

                <!-- incomplete -->
                <div v-else-if="plan.status === 'incomplete'">
                    Your subscription is incomplete, please contact support
                </div>

                <!-- incomplete -->
                <div v-else-if="plan.status === 'incomplete_expired'">
                    Your subscription is incomplete (expired), please contact support
                </div>

            </v-system-bar>
        </template>
        <ExpiredTrialDialog :toggle.sync="expiredTrialDialogToggle"/>
        <PlanDialog :toggle.sync="planDialogToggle" :plan="plan" @subscribed="subscribed"></PlanDialog>
    </div>
</template>

<script>
import SubscribeButton from "@/views/Manage/Billing/SubscribeButton.vue";
import { mapGetters } from 'vuex'
import {DateTime} from "luxon";
import PlanDialog from "@/views/Manage/Billing/PlanDialog.vue";
import ExpiredTrialDialog from "@/views/Manage/Billing/ExpiredTrialDialog.vue";
import Vue from "vue";

export default {
    components: {
        PlanDialog,
        SubscribeButton,
        ExpiredTrialDialog,
    },
    data() {
        return {
            loading: false,
            error: "",
            planDialogToggle: false,
            expiredTrialDialogToggle: false,
            plan: {},
            visible: false,
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: "isAdmin",
            isOnboarded: "isOnboarded",
            checkFeatureFlag: "checkFeatureFlag",
        }),
        isAdminAndOnboarded() {
            return this.isAdmin && this.isOnboarded
        },
        shouldShowTrialBar() {
            if (this.plan.status !== 'active' && this.visible && !this.loading) {
                if (this.checkFeatureFlag('lighthouse-customer')) {
                    if (this.calcTrialDaysRemaining(this.plan) <= 14) {
                        return true
                    }
                } else {
                    return true
                }
            }
            return false
        }
    },
    methods: {
        calcTrialDaysRemaining(plan) {
            const currentTime = DateTime.now().toUnixInteger()
            return Math.floor((plan.trialEnd - currentTime) / 60 / 60 / 24)
        },
        subscribed() {
            this.getPlan()
        },
        getPlan() {
            if (!this.isAdminAndOnboarded) {
                return
            }

            this.loading = true
            // get the subscription for this org
            this.$http.get('/api/v1/billing/plan').then(response => {
                this.plan = response.data
                this.visible = !!this.plan.status
                // eslint-disable-next-line
                Vue.prototype.$stripe = Stripe(this.plan.stripePublishableKey)

            }).catch(error => {
                // TODO in the future we'll want to block loading the dashboard on login, for now we'll hold this state and do nothing
                console.log(error)
                this.error = error?.response?.data?.message || "could not get plan"
            }).finally(() => {
                this.loading = false
                this.planDialogToggle = (!this.plan.lookupKey) && this.isAdminAndOnboarded && !this.loading && !this.error
            })
        },
        setExpiredTrialDialogToggle() {
            const hideExpiredDialogRoutes = ["manage-settings", "manage-purchase"]
            if (hideExpiredDialogRoutes.includes(this.$route.name)) {
                this.expiredTrialDialogToggle = false
            } else {
                this.expiredTrialDialogToggle = this.plan.status === 'paused'
            }
        }
    },
    mounted() {
        this.getPlan()
    },
    watch: {
        "$route.name": function() {
            this.setExpiredTrialDialogToggle()
        },
        plan() {
            this.setExpiredTrialDialogToggle()
        },
    }
}
</script>
