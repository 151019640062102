<template>
    <div v-if="getOrgSetting('logoUrl')" class="d-flex align-center" :class="dark ? 'theme--dark' : ''"
         :style="{opacity: 0.60, 'font-size': '12px', width: 'fit-content', filter }">
        Powered by<YeshIDLogo class="ml-1" width="64" height="16" :black="dark"/>
    </div>
</template>

<script>
import YeshIDLogo from "@/components/YeshIDLogo.vue"
import {mapGetters} from "vuex";

export default {
    components: {
        YeshIDLogo,
    },
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        gray: {
            type: Boolean,
            default: false,
        },
        align: {
            type: String,
        },
    },
    computed: {
        ...mapGetters({
            getOrgSetting: "getOrgSetting",
        }),
        filter() {
            if (this.dark) {
                return 'grayscale(1) brightness(2.5)'
            }

            if (this.gray) {
                return 'grayscale(1)'
            }

            return ''
        }
    }
}
</script>