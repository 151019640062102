<template>
    <LoadingRing v-if="loading"/>
    <div v-else class="grid-layout-column grid-gap-lg">
        <div class="grid-layout ma-6">
            <div>
                <div class="mb-10">
                    <h4>Here is all your scope</h4>
                    <h4>data by status</h4>
                </div>
                <div class="grid-layout grid-gap-lg grid-basis-sm mb-8">
                    <NumberWithLegend label="Restricted" color="error-icon" :number="oauthGrantsOverview.data.restrictedScopes" :subNumber="getUserCount('RESTRICTED')" subNumberLabel="Users"/>
                    <NumberWithLegend label="Sensitive" color="warning" :number="oauthGrantsOverview.data.sensitiveScopes" :subNumber="getUserCount('SENSITIVE')" subNumberLabel="Users"/>
                    <NumberWithLegend label="Neutral" color="success" :number="oauthGrantsOverview.data.neutralScopes" :subNumber="getUserCount('NEUTRAL')" subNumberLabel="Users"/>
                </div>
                <div class="grid-layout grid-basis-auto">
                    <v-btn color="primary" elevation="0" @click="$emit('seeDetails')">See details</v-btn>
                    <v-btn outlined text elevation="0" @click="scopeDefintionsToggle = true">What are scopes?</v-btn>
                </div>
            </div>
            <div>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs" style="cursor: pointer;" @click="$emit('seeDetails')">
                            <v-icon v-for="(scope, i) in scopeGrid" :key="`${i}-grid-circle`"
                                class="pa-1" :color="getColor(scope.category)" size="36" :disabled="!scope.name">
                                mdi-circle
                            </v-icon>
                        </div>
                    </template>
                    Click anywhere to see all the details
                </v-tooltip>
            </div>
        </div>
        <ScopeDefinitionsDialog :toggle.sync="scopeDefintionsToggle"/>
    </div>
</template>

<style scoped>
.theme--light.v-icon.v-icon.v-icon--disabled {
    color: #e0e0e0 !important;
}
</style>

<script>
import NumberWithLegend from './NumberWithLegend.vue';
import ScopeDefinitionsDialog from './ScopeDefinitionsDialog.vue';
import SecurityScopesCell from './SecurityScopesCell.vue';
import LoadingRing from '@/components/LoadingRing.vue';

export default {
    components: {
        NumberWithLegend,
        ScopeDefinitionsDialog,
        LoadingRing
    },
    props: {
        oauthGrantsOverview: {
            type: Object,
            default: Object,
            required: true,
        },
        thirdpartyApps: {
            type: Object,
            default: Object,
            required: true,
        },
    },
    data() {
        return {
            scopeDefintionsToggle: false,
        }
    },
    computed: {
        loading() {
            return this.oauthGrantsOverview.loading || this.thirdpartyApps.loading
        },
        scopeGrid() {
            const allScopes = this.thirdpartyApps.data.scopes
            const length = 204
            let arr = []

            const scopesSet = {}
            for (const app of this.thirdpartyApps.data.applications) {
                for (const scopeName of app.scopes) {
                    const scope = { ...allScopes[scopeName] }
                    // format scope name correctly for the tooltip
                    scope.name = SecurityScopesCell.scopeName(scope)
                    scopesSet[scopeName] = scope
                }
            }

            const scopes = Object.values(scopesSet)
            const neutral = scopes.filter(s => s.category === "NEUTRAL")
            const sensitive = scopes.filter(s => s.category === "SENSITIVE")
            const restricted = scopes.filter(s => s.category === "RESTRICTED")

            const allCategoryScopes = [...neutral, ...sensitive, ...restricted]
            for (let i = 0; i < length - allCategoryScopes.length; i++) {
                arr[i] = {}
            }

            arr = arr.concat(allCategoryScopes)
            return arr
        },
        categoryUsers() {
            return this.thirdpartyApps.data.scopeCategoryUsers
        },
    },
    methods: {
        getColor(category) {
            switch(category) {
                case "NEUTRAL":
                    return "success"
                case "SENSITIVE":
                    return "warning"
                case "RESTRICTED":
                    return "error"
                default:
                    return "white"
            }
        },
        getUserCount(category) {
            const categoryUserCount = this.categoryUsers.find(c => c.category === category) || {}
            return categoryUserCount.numUsers || 0
        },
    }
}
</script>