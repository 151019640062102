<template>
    <v-sheet color="info-bg" class="pa-4" rounded>
        <v-layout align-center justify-space-between>
            <div class="text-h5">
                <slot/>
            </div>
            <img :src="image" class="mx-4" style="margin: -12px; height: 100px;"/>
        </v-layout>
    </v-sheet>
</template>


<script>
export default {
    name: "FormBanner",
    props: {
        image: {
            type: String,
            required: true
        },
        textClass: {

        }
    }
}
</script>
