<template>
    <AlertBanner v-if="provisioningConfig?.threeLeggedOauthRequired()" type="info-no-bg">Make sure your browser pop-ups are not blocked</AlertBanner>
</template>

<script>
import ProvisioningConfig from "@/lib/application/provisioning_config"
import AlertBanner from "@/components/AlertBanner.vue"

export default {
    components: {
        AlertBanner,
    },
    props: {
        provisioningConfig: {
            type: ProvisioningConfig,
        }
    },
}
</script>