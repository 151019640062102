<template>
    <v-img v-if="($vuetify.theme.dark && !white) || black" :max-width="width" :max-height="height" src="@/assets/logo-white.svg" contain :position="position"/>
    <v-img v-else :max-width="width" :max-height="height" src="@/assets/logo-black.svg" contain :position="position"/>
</template>

<script>
export default {
    props: {
        black: {
            type: Boolean,
            default: false,
        },
        white: {
            type: Boolean,
            default: false,
        },
        width: {
            default: "96",
        },
        height: {
            default: "24",
        },
        position: {
            type: String,
        }
    },
}
</script>