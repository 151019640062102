<template>
    <div><v-tooltip top :disabled="!tooltip">
        <template v-slot:activator="{ on, attrs }">

                <v-avatar v-on="on" v-bind="attrs" :color="computedColor" class="d-flex rounded" :class="computedTextColorClass" :style="computedTextColorStyle" :size="size" tile>
                    <span v-if="iconSrc == '' || iconLoadingFailed">{{ getInitials() }}</span>
                    <v-img v-else-if="iconSrc && !iconLoadingFailed" :src="iconSrc" v-on:error="iconLoadingFailed = true" v-on:load="iconLoadingFailed = false"/>
                    <v-icon v-else color="info-icon" :size="size">{{ placeholderIcon }}</v-icon>
                </v-avatar>
        </template>
        <span>{{ application.name }}</span>
    </v-tooltip></div>
</template>

<script>

// TODO extract out random color v-avatar into component that accepts functions/properties to display initials and selector for the value to hash
// use slot to display like a user image
// get rid of color prop since component computes it

export default {
    props: {
        // the application object
        application: {
            type: Object,
            required: true
        },
        // the size of the icon in pixels
        size: {
            type: Number,
            required: false,
            default: 24
        },
        nocache: {
            type: Boolean,
            required: false,
            default: false
        },
        color: {
            type: String,
            required: false,
        },
        placeholderIcon: {
            type: String,
            required: false,
            default: "",
        },
        tooltip: {
            type: Boolean,
            required: false,
            default: false
        },
        circle: {
            type: Boolean,
            required: false,
            default: false
        },
        inverted: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            iconLoadingFailed: false,
        }
    },
    computed: {
        iconSrc: function () {

            if (!this.application.icon && this.application.domain) {
                return 'https://logo.clearbit.com/' + this.application.domain + '?size=' + this.size
            }

            if (this.nocache) {
                return this.application.icon + '?t=' + Date.now()
            }

            return this.application.icon
        },
        computedColor() {
            if (this.inverted) {
                return "white"
            }

            return this.pickColor()
        },
        computedTextColorStyle() {
            let color = "white"
            if (this.inverted) {
                color = this.pickColor()
            }

            return { color }
        },
        computedTextColorClass() {
            return `${this.computedTextColorStyle.color}--text`
        }
    },
    methods: {
        getInitials() {
            let initials = "?"

            try {
                if (this.application.name) {
                    initials = (this.application.name || "").charAt(0)
                    initials = initials.toUpperCase()
                }
            } catch {
                console.log("could not get applications initial")
            }

            return initials
        },
        pickColor() {
            if (this.color) {
                return this.color
            }

            if (!this.application.name) {
                return "grey"
            }

            if (this.iconSrc && !this.iconLoadingFailed) {
                return "transparent"
            }

            // fiddle with these ranges to get the desired color range
            let hRange = [0, 360];
            let sRange = [45, 80];
            let lRange = [30, 60];

            let hash = this.getHashOfString(this.application.name);
            let h = this.normalizeHash(hash, hRange[0], hRange[1]);
            let s = this.normalizeHash(hash, sRange[0], sRange[1]);
            let l = this.normalizeHash(hash, lRange[0], lRange[1]);
            return `hsl(${h}, ${s}%, ${l}%)`;
        },
        normalizeHash(hash, min, max) {
            return Math.floor((hash % (max - min)) + min);
        },
        getHashOfString(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            hash = Math.abs(hash);
            return hash;
        }
    },
    watch: {
        // whenever application changes, update the icon
        application: function () {
            this.iconLoadingFailed = false
        },
    },
}

</script>
