<template>
    <v-navigation-drawer
        :value="visible"
        fixed stateless :width="widthPX" right
        style="box-shadow: -4px 0px 0px rgba(0, 0, 0, 0.16);">
        <v-card flat>
            <v-layout :class="!dense ? 'pa-6 pb-0' : 'pa-2 pl-4'">
                <v-btn v-if="$listeners.back" class="mr-4" icon @click="$listeners.back"><v-icon size="24">mdi-arrow-left</v-icon></v-btn>
                <slot name="prepend-title"/>
                <v-flex :class="$slots['prepend-title'] ? 'ml-4' : ''">
                    <v-layout>
                        <b v-if="dense"><slot name="title"/></b>
                        <h5 v-else class="mr-4"><slot name="title"/></h5>
                        <slot name="append-title" />
                    </v-layout>
                    <slot name="subtitle"/>
                    <slot name="description"/>
                </v-flex>
                <v-spacer></v-spacer>
                <v-btn data-cy="close-sidepanel-btn" @click="close" height="26" width="26" icon class="float-right">
                    <v-icon :size="!dense ? 24 : 20">mdi-close</v-icon>
                </v-btn>
            </v-layout>
        </v-card>

        <v-card v-if="$scopedSlots['actions']" flat :class="!dense ? 'pa-6 pb-0' : ''">

            <slot name="actions"/>
        </v-card>

        <v-card v-if="$scopedSlots['tabs']" flat :class="!dense ? 'pa-6 pb-0' : ''">
            <slot name="tabs"/>
        </v-card>

        <v-divider/>

        <v-card flat :class="!dense ? 'pa-6' : ''">
            <slot/>
        </v-card>

        <template v-if="$scopedSlots['footer-actions']" slot="append">
            <v-card color="flex-shrink-0"
                style="border-radius: 0; border-top: 1px solid #e0e0e0; box-shadow: none;">
                <v-card-actions class="grey lighten-5 px-4 py-3">
                    <v-spacer></v-spacer>
                    <slot name="footer-actions"/>
                </v-card-actions>
            </v-card>
        </template>
    </v-navigation-drawer>
</template>

<script>

export default {
    name: "SidePanel",
    props: {
        width: {
            type: Number,
            default: 800,
        },
        title: {
            type: String,
            default: "",
        },
        visible: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Function,
            default: () => {},
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        widthPX() {
            return this.width + 'px'
        }
    },
    watch: {
        visible: function(newValue) {
            if (newValue) {
                window.addEventListener('keydown', this.keyDownHandler)
            } else {
                window.removeEventListener('keydown', this.keyDownHandler)
            }
        }
    },
    methods: {
        keyDownHandler(event) {
            if (event.code === 'Escape' && event.target && event.target.nodeName === 'BODY') {
                this.close()
            }
        }
    },
    destroyed() {
        window.removeEventListener('keydown', this.keyDownHandler)
    }
}

</script>

