<template>
    <v-menu
        :value="open"
        :close-on-content-click=false
        offset-y
        >
        <template v-slot:activator="{ attrs, on }">
            <v-chip
                class="my-1"
                outlined
                v-bind="attrs"
                :input-value="internalFilter.selected"
                close
                @click="open = true"
                @click:close="close"
                @hook:mounted="delayedOpen()" 
                v-on="on">
                {{ internalFilter.text }} is {{ getSelectedText() }}
            </v-chip>
        </template>
        <v-sheet class="pa-4">
            <FormTextField
                :label="internalFilter.text"
                :value="getValue()"
                @change="setValue"
                @blur="() => getSelectedText(internalFilter) && open && applyFilter()"
                @keydown.enter="applyFilter"
                autofocus/>
        </v-sheet>
    </v-menu>
</template>



<script>
import FormTextField from '../FormTextField.vue';

export default {
    props: {
        filter: {
            type: Object,
            default: Object,
        },
        initialFilters: {
            type: Array,
            default: Array,
        }
    },
    data() {
        return {
            open: false,
        };
    },
    mounted() {
        if (!this.internalFilter.items) {
            // for some reason this is being mounted twice and sometimes doesn't have items
            return
        }

        for (const filter of this.initialFilters) {
            if (filter.value) {
                this.setValue(filter.value)
            }
        }

        if (this.hasValue()) {
            this.$emit("initialApplied", this.internalFilter)
        }
    },
    computed: {
        internalFilter: {
            get() {
                return this.filter;
            },
            set(value) {
                this.$emit("update:filter", value);
            }
        },
    },
    methods: {
        // TODO: FIXME
        delayedOpen() {
            setTimeout(() => {
                if (!this.hasValue()) {
                    this.open = true;
                }
            }, 1);
        },
        hasValue() {
            return this.getValue().length > 0
        },
        getSelected() {
            if (!this.internalFilter.selected.length) {
                this.internalFilter.selected[0] = this.internalFilter.items[0]
            }

            return this.internalFilter.selected[0]
        },
        getSelectedText() {
            return `"${this.getSelected().value || ""}"`
        },
        getValue() {
            return this.getSelected().value
        },
        setValue(value) {
            this.getSelected().value = value

        },
        close() {
            this.getSelected().value = ""
            this.$emit('remove')
        },
        applyFilter() {
            this.$emit('applied', this.internalFilter)
            this.open = false
        }
    },
    components: { FormTextField }
}
</script>