<template>
    <div>
        <InputLabelDescription v-if="label" :label="label" :optional="optional" :description="description" :info="info"/>
        <v-select v-show="edit" :class="textClass" v-bind="$props" v-on="$listeners" outlined hide-details dense
            height="36" disable-lookup single-line :label="defaultLabel" clear-icon="mdi-close-circle"
            append-icon="mdi-chevron-down" :menu-props="{ offsetY: true, }">
            <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
                <slot :name="name" v-bind="data" />
            </template>
            <template #prepend-inner>
                <slot name="prepend-inner"/>
            </template>
            <template #item="data">
                <v-list-item class="pr-0" v-on="data.on" v-bind="data.attrs" #default="{ active }">
                    <v-list-item-content>
                        <v-list-item-title>
                            <slot name="item" v-bind="data">

                                {{ getItemText(data.item) }}
                            </slot>
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon v-show="active" class="align-self-end" size="12" color="primary">mdi-check-bold</v-icon>
                    </v-list-item-action>
                </v-list-item>
            </template>
        </v-select>
        <div v-show="!edit">
            <slot name="selection" v-bind="{item: selectedItem}">

                {{ getItemText(value) }}
            </slot>
        </div>
    </div>
</template>

<script>
import { VSelect } from 'vuetify/lib'
import InputLabelDescription from './InputLabelDescription.vue';

export default {
    name: "FormSelect",
    extends: VSelect,
    props: {
        defaultLabel: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: ""
        },
        optional: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            required: false,
            default: "",
        },
        info: {
            type: String,
            required: false,
            default: "",
        },
        hideDetails: {
            type: [String, Boolean],
            default: "auto",
            required: false,
        },
        edit: {
            type: Boolean,
            default: true,
        },
        textClass: {
            type: String,
            default: "text-body-2"
        }
    },
    methods: {
        getItemText(item) {
            if (item && typeof item === 'object') {
                return item[this.itemText]
            }

            return item
        }
    },
    computed: {
        selectedItem() {
            const value = this.value
            if (!value) {
                return null
            }

            if (value && typeof value === 'object') {
                return value
            }

            const selected = this.items.find(item => {
                if (item === value) {
                    return item
                }

                if (typeof item === 'object' && item[this.itemValue] === value) {
                    return item
                }
            })

            return selected
        }
    },
    components: { InputLabelDescription }
}
</script>