import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import { setupCache } from 'axios-cache-interceptor';
import store from './store'
import api from './api'
import mixins from './mixins';

import VueMeta from 'vue-meta';

import posthog from 'posthog-js'

try {

  if (window.location.hostname === 'app.yeshid.com') {
    posthog.init('phc_XwKYhxR8vRhSy1KqOby9hgXIgkjJfevdrGcmf4rPVRe',{
      api_host: 'https://app.yeshid.com/api/v1/posthog',
      ui_host: 'https://app.posthog.com',
    })
  }

  router.afterEach(() => {
    // Sends a $pageview event to PostHog every time the route changes
    posthog.capture('$pageview');
  });
} catch (e) {
  console.log('error initializing posthog', e)
}

Vue.use(VueMeta);
Vue.mixin(mixins)

// sets up the cache on the default axios instance
setupCache(axios)
// we set the default cache property "override" to true so that
// the cache interceptor isn't caching every request by default
axios.defaults.cache = { ...(axios?.defaults?.cache || {}), override: true }
// to use cached results, when making a request, pass in a config object that has cache override set to false
// config = {
//   cache: { override: false }
// }

// allow the whole app to use axios globally, eg: "this.$http.get()"
Vue.prototype.$http = axios
Vue.prototype.$api = api

function setCSRFTokenHeader() {
  return axios.get('/api/v1/csrf').then(response => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrfToken;
  }).catch(error => {
    console.error('Error fetching csrf token', error);
  });
}


// intercept all responses and redirect to login if the response is 401
axios.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {

      // Note: this is susceptible to race conditions because requests can fail concurrently
      // if not already on the login or logout page, redirect to login via logout
      if (router.currentRoute.name !== 'login' && router.currentRoute.name !== 'logout') {
        // if there's already a redirect defined use it, or default to the current fullPath
        const redirect = router.currentRoute.query.redirect || router.currentRoute.fullPath
        router.push({ name: 'logout', query: { redirect } })
        console.error('unauthorized request, redirecting to login')
      }
    }
    return Promise.reject(error)
  }
)


// enables performance measuring in chrome
const isDev = process.env.NODE_ENV !== "production"
Vue.config.performance = isDev

Vue.config.productionTip = false

setCSRFTokenHeader().catch(error => {
  console.error('Error setting csrf token', error);
}).finally(() => {
  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
});