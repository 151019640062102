<template>
    <BaseDialog :show.sync="dialogToggle" :width="550">
        <template slot="content">
            <div class="dialog-gradient grid-layout-column grid-gap-xl" style="margin: -24px; padding: 24px;">
                <h5 class="mb-4 blue-text--text" style="white-space: pre-wrap;">We’re sorry but your trial has expired.</h5>
                <div class="grid-layout grid-basis-sm grid-gap-xxl">
                    <div>
                        <SubscribeButton to="/manage/billing/purchase" height="52" block @click="close" />
                    </div>
                    <div>
                        <v-btn color="primary" elevation="0" height="52" href="mailto:support@yeshid.com" block>Contact Support</v-btn>
                    </div>
                </div>
                <v-btn class="mt-1" outlined color="error" to="/manage/settings" height="52" block @click="close">Delete Account</v-btn>
            </div>
        </template>
    </BaseDialog>
</template>

<script>

import BaseDialog from '@/components/dialog/BaseDialog.vue'
import SubscribeButton from "@/views/Manage/Billing/SubscribeButton.vue";

export default {
    components: {
        SubscribeButton,
        BaseDialog
    },
    props: {
        toggle: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        dialogToggle: {
            get() {
                return this.toggle;
            },
            set(value) {
                this.$emit("update:toggle", value);
            },
        },
    },
    methods: {
        close() {
            this.dialogToggle = false
        },
        goToPay() {
            this.$router.push(`/manage/billing/purchase`)
            this.close()
        },
    }
}
</script>


<style lang="scss" scoped>
    .dialog-gradient {
        background: linear-gradient(180deg, #EBF6FF 0%, #F6FBFF 100%);
    }
    .feature {
        color: $blue-text;
    }

    .theme--dark .dialog-gradient {
        background: linear-gradient(180deg, #213547 0%, #222325 100%);
    }
    .theme--dark .feature {
        color: $blue-text--dark;
    }
</style>