<template>
    <div>
        <LoadingRing v-if="loading" text="scopes"/>
        <AlertBanner class="mb-4" type="info">You are looking at the scopes (permissions) your users have granted to third party applications.</AlertBanner>
        <svg v-show="!loading" id="security-viz"></svg>
        <div class="tooltip" :style="tooltipcardStyles()">
            <div class="body-2" style="max-width: 450px; background-color: #1e1e1e; color: #fff; border-radius: 4px; padding: 10px;">
                <div class="font-weight-bold">{{ tooltip.type }}:</div>
                <div>{{ tooltip.name }}</div>
                <div class="mt-2 mb-3 caption font-italic">{{ tooltip.description }}</div>
                <div v-if="shownApps.length" class="grid-layout grid-basis-auto">
                    <GroupedAppsByCategory :applications="shownApps" :scopes="allScopes" :max="maxTooltipApps" :size="16" :categories="[tooltip.category]"/>
                </div>
                <div class="mt-2 caption">Click for details.</div>
            </div>
        </div>
        <SidePanel :visible="showSidePanel" :width="400" :close="closeSidePanel" dense>
            <template #title>Scope Details</template>
            <template #subtitle><b class="text-caption" :class="$vuetify.theme.dark ? 'white--text' : 'text-default--text'">"{{ selectedScope.scope }}"</b></template>
            <template #description>
                <div v-show="selectedScope.scope && allScopes[selectedScope.scope].description" class="text-caption" :class="$vuetify.theme.dark ? 'white--text' : 'text-default--text'">
                    <div>Scope Definition:</div>
                    <div class="font-italic">{{ selectedScope.scope && allScopes[selectedScope.scope].description }}</div>
                </div>
            </template>
            <FormTextField class="pa-1" v-model="search" prepend-inner-icon="mdi-magnify" placeholder="Search" :outlined="false" solo flat/>
            <div class="text-caption d-flex justify-space-between my-2 mx-4" :class="$vuetify.theme.dark ? 'white--text' : 'text-default--text'">
                <div>Application</div>
                <div>Users</div>
            </div>
            <v-list-item class="d-flex text-caption" :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
                         v-bind:key="app.name" v-for="app in searchFiltered(selectedScope.apps)" dense @click="selectedApplication = app; securityPanelVisible = true">
                <div class="d-flex align-center">
                    <ApplicationIcon :application="app" />
                    <b class="ml-2">{{ app.name }}</b>
                </div>
                <v-spacer/>
                <div class="d-flex text-right align-center">
                    <b>{{ app.numberOfUsers }}</b>
                </div>
            </v-list-item>
        </SidePanel>
        <SecuritySidePanel @grantRevoked="refresh" :application="selectedApplication" tab="users" :visible.sync="securityPanelVisible" @close="closeSidePanel" @back="securityPanelVisible = false"/>
    </div>
</template>

<style scoped>
.tooltip:before {
    content:'';
    display:block;
    width:0;
    height:0;
    position:absolute;
    
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent; 
    border-right: 12px solid #1e1e1e;
    left: -6px;
    
    top: 6px;
}
</style>

<script>

import * as d3 from 'd3'

import ApplicationIcon from '@/components/ApplicationIcon.vue'
import SidePanel from '@/components/SidePanel.vue'
import SecuritySidePanel from './SecuritySidePanel.vue'
import LoadingRing from '@/components/LoadingRing.vue'
import FormTextField from '@/components/FormTextField.vue'
import GroupedAppsByCategory from './GroupedAppsByCategory.vue'
import AlertBanner from '@/components/AlertBanner.vue'
import { DateTime } from 'luxon'

const rootName = 'root'

export default {
    components: {
    ApplicationIcon,
    SidePanel,
    SecuritySidePanel,
    LoadingRing,
    FormTextField,
    GroupedAppsByCategory,
    AlertBanner
},
    props: {
        scopeCategoryFilter: {
            type: Array,
            default: () => ["all"],
        },
        scopeFilter: {
            type: String,
            default: '',
        },
        selectedAppGroup: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            maxTooltipApps: 20,
            loading: false,
            search: "",
            zoomLayer: null,
            simulation: null,
            selectedScope: {},
            selectedScopeNode: null,
            showSidePanel: false,
            securityPanelVisible: false,
            selectedApplication: {},
            tooltip: {},
            allScopes: {},
            tooltipXY: [-1, -1],
            applications: [],
            highlighted: '',
        }
    },
    async mounted() {
        this.init()
        await this.get3rdParty()
        this.draw()
    },
    computed: {
        shownApps() {
            if (this.tooltip.apps) {
                return Object.values(this.tooltip.apps).slice(0, this.maxTooltipApps)
            }
            return []
        },
        zoomed() {
            const pathParts = this.$route.path.split("/")
            const lastPath = pathParts[pathParts.length - 1]
            return lastPath === "scopes" || lastPath === "security" ? "" : lastPath
        }
    },
    watch: {
        selectedAppGroup() {
            this.clear()
            this.draw()
        },
        scopeCategoryFilter() {
            this.clear()
            this.draw()
        },
        scopeFilter() {
            this.clear()
            this.draw()
        },
        zoomed() {
            this.clear()
            this.draw()
        }
    },
    methods: {
        zoom(zoomTo) {
            if (!this.zoomed && zoomTo !== rootName) {
                this.$emit('scopeClicked', zoomTo)
            }
        },
        async refresh() {
            await this.get3rdParty()
            this.clear()
            this.draw()
        },
        closeSidePanel() {
            this.showSidePanel = false
            this.securityPanelVisible = false
            this.unselect(this)
            this.unfade()
        },
        scopeCategory(scope) {
            return this.allScopes[scope].category.toLowerCase()
        },
        scopeColor(scope) {
            switch (this.scopeCategory(scope)) {
                case 'neutral':
                    return '#4dae51'
                case 'sensitive':
                    return '#ff930e'
                case 'restricted':
                    return '#f44336'
                default:
                    return '#ddd'
            }
        },
        tooltipcardStyles() {
            const [x, y] = this.tooltipXY
            return {
                position: 'fixed',
                display: x > -1 && y > -1 ? '' : 'none',
                top: y + 'px',
                left: (x + 10) + 'px',
                "z-index": 555,
            }
        },
        async get3rdParty() {
            this.loading = true
            let resp = await this.$http.get('/api/v1/applications/thirdparty')
            this.applications = resp.data.applications
            this.allScopes = resp.data.scopes
            this.loading = false
        },
        processData() {
            const struct = {name: rootName, children: []}

            // we retrieve third party applications that are organized by application
            // so we need to invert that to draw a unique set of scopes
            let scopesSet = {}
            this.applications.forEach(a => {
                a.scopes.forEach(s => {
                    if (this.selectedAppGroup === "forbidden" && !a.forbidden) {
                        return
                    }

                    if (this.selectedAppGroup === "new" && (!a.createdAt || DateTime.fromISO(a.createdAt || new Date().toISOString()).diffNow("days").as("days") > 7)) {
                        return
                    }

                    const description = this.allScopes[s]?.description
                    const category = this.allScopes[s]?.category

                    // if we're filtering on scope categories we skip over nodes that don't match that category
                    if (!this.scopeCategoryFilter.includes('all')) {
                        if (!this.scopeCategoryFilter.includes(category.toLowerCase())) {
                            return
                        }
                    }

                    // if we're filtering for scope names then skip anything that doesn't match
                    if (this.scopeFilter) {
                        const scopeFilterLower = this.scopeFilter.toLocaleLowerCase()
                        const notAppName = !a.name.toLocaleLowerCase().includes(scopeFilterLower)
                        const notScopeName = !s.toLocaleLowerCase().includes(scopeFilterLower)

                        if (notAppName && notScopeName) {
                            return
                        }
                    }

                    let name = ''

                    try {
                        // for the scopes that look like "https://etc/auth/some.scope.name" we want to use "some.scope.name"
                        // but there are others that are just a url like "https://etc/some/scope/name" so we want to turn those into "some.scope.name"
                        // for consistency
                        const u = new URL(s)
                        name = u.pathname.substring(1).replace('auth/', '').replaceAll('/', '.')
                        if (name.charAt(name.length - 1) == '.') {
                            name = name.substring(0, name.length - 1)
                        }
                        if (name == "") {
                            name = s // just use the url then otherwise we have nothing to show
                        }
                    } catch {
                        // if it's not a url (eg: 'openid') then leave it alone
                        name = s
                    }

                    if (scopesSet[name]) {
                        scopesSet[name][a.name] = a
                    } else {
                        scopesSet[name] = {[a.name]: a}
                    }

                    // store the description and category in a global lookup table for future referencing
                    this.allScopes[name] = {description, category}
                })
            })

            const scopesData = Object.keys(scopesSet)
            
            // construct the tree from the scopes where 'some.scope.name' -> {name: 'some': children: [{name: 'scope': children: [{name: 'name'}]}]}
            for (let i = 0;i < scopesData.length;i++) {
                if (scopesData[i].startsWith("http")) {
                    // one of the URLs we left alone, just insert it
                    struct.children.push({name: scopesData[i], parent: struct, apps: scopesSet[scopesData[i]], scope: scopesData[i], children: []})
                    continue
                }

                const parts = scopesData[i].split('.')
                let node = struct
                for (let j = 0;j < parts.length;j++) {
                    let idx = node.children.findIndex(x => x.name == parts[j])
                    if (idx < 0) {
                        let newnode = {}
                        if (j == parts.length - 1) {
                            newnode = {name: parts[j], parent: node, apps: scopesSet[scopesData[i]], scope: scopesData[i], children: [], category: parts[0]}
                        } else {
                            newnode = {name: parts[j], parent: node, toplevel: j == 0, children: []}
                        }
                        node.children.push(newnode)
                        node = newnode
                    } else {
                        node = node.children[idx]
                        if (j == 0 && !node.toplevel) {
                            // we're at a part of the tree that is both a scope and the tree to more scopes
                            node.toplevel = true
                        }
                    }
                }
            }

            // collapse some of the tree nodes so we don't have long branches that only lead to one leaf
            const stack = [struct]
            while (stack.length) {
                let cur = stack.pop()
                // we're looking to remove nodes that only have one child and do not directly represent one of the 
                // scopes (ie: if the node has apps then it is not just a link node)
                if (!cur.apps && cur.children.length == 1 && cur.parent) {
                    const parent = cur.parent

                    // merge this node's children into this node's parent's children and remove 
                    // this node from the parent
                    parent.children = parent.children.filter(c => c.name != cur.name)
                    parent.children.push(...cur.children)

                    if (cur.toplevel && cur.children[0].children.length) {
                        // if we're losing a 'category' node and we're not at the end of the branch we'll push that 
                        // classification up
                        cur.children[0].toplevel = true
                    }

                    // update the parent linkage so that this node's children now point back to this node's parent 
                    cur.children.forEach(child => {
                        child.parent = parent
                        child.name = cur.name += "." + child.name // update the name so we don't lose the path
                    })
                }

                stack.push(...cur.children)
            }

            return struct
        },
        init() {
            // Specify the chart’s dimensions.
            const width = window.innerWidth;
            let height = window.innerHeight - 400;
            if (height < 600) {
                height = 600
            }

            const svg = d3.select("#security-viz")
                .attr("width", width)
                .attr("height", height)
                .attr("viewBox", [-width / 2, -height / 2, width, height])
                .attr("style", "max-width: 100%; height: auto;");

            this.zoomLayer = svg.append("g")
        },
        clear() {
            this.zoomLayer.selectAll("*").remove()
        },
        draw() {
            const that = this
            let data = this.processData()
            if (!data.children.length) {
                return
            }
            
            if (this.zoomed) {
                if (this.zoomed == rootName) {
                    data = { name: rootName, children: data.children.filter(c => !c.toplevel) }
                } else {
                    data = data.children.find(c => c.name == this.zoomed)
                }
            }

            // setup the data nodes
            const root = d3.hierarchy(data);
            const links = root.links();
            const nodes = root.descendants();

            function createSimulation(nodes, distance = 15, strength = -280) {
                return d3.forceSimulation(nodes)
                    .force("link", d3.forceLink(links).id(d => d.id).distance(distance).strength(1))
                    .force("charge", d3.forceManyBody().strength(strength))
                    .force("x", d3.forceX())
                    .force("y", d3.forceY())
            }

            // if we're in "zoomed" mode we want to apply a forceCollide function so that nodes don't overlap
            // TODO would be good to have a force function to ensure that a 'focused' node in the zoomed graph doesn't have any other nodes underneath it
            let simulation
            if (this.zoomed) {
                simulation = createSimulation(nodes, 150, -1500)
                simulation.force("collide", d3.forceCollide(70))
            } else {
                var catForce = d3.forceManyBody().strength(-3500)

                // Save the default initialization method
                var init = catForce.initialize;

                // Custom implementation of .initialize() calling the saved method with only
                // a subset of nodes
                catForce.initialize = function (nodes) {
                    // Filter subset of nodes and delegate to saved initialization.
                    init(nodes.filter(n => n.data.toplevel))
                }

                simulation = createSimulation(nodes)
                simulation.force("cat", catForce)
            }

            this.simulation = simulation

            function dragstarted(event, d) {
                if (!event.active) simulation.alphaTarget(0.2).restart();
                d.fx = d.x;
                d.fy = d.y;
            }
            
            function dragged(event, d) {
                d.fx = event.x;
                d.fy = event.y;
            }
            
            function dragended(event, d) {
                if (!event.active) simulation.alphaTarget(0);
                d.fx = null;
                d.fy = null;
            }
            
            const drag = d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended)

            // append links
            const link = this.zoomLayer.append("g")
                .attr("stroke", "#999")
                .attr("stroke-opacity", 0.6)
                .selectAll("line")
                .data(links)
                .join("line")
                .attr("class", "link")

            // append leaf nodes
            const node = this.zoomed ? this.drawZoomedScopes(this.zoomLayer, nodes, drag) : this.drawScopes(this.zoomLayer, nodes, drag)

            // append category nodes
            const category = this.zoomLayer.append("g")
                .selectAll("g")
                .data(nodes.filter(n => !this.zoomed && n.data.toplevel))
                .join("g")
                .attr("cursor", "pointer")
                .on("click.zoom", (e, d) => {
                    this.tooltipXY = [-1, -1]
                    return this.zoom(d.data.name)
                })
                .call(drag)

            const rect = category
                .append("rect")
                .attr("height", "24")
                .attr("fill", "#000")
                .attr("stroke", d => {
                    if (d.data.apps) {
                        return this.scopeColor(d.data.name)
                    }
                    return ''
                })
                .attr("stroke-width", "2")
                .attr("rx", "5")

            category
                .append("text")
                .attr("font-size", "18")
                .attr("x", "10")
                .attr("y", "18")
                .attr("fill", "#fff")
                .text(d => d.data.name)
                .on("mouseenter", (e, d) => {
                    if (d.data.scope) {
                        that.tooltip = {
                            type: "Scope",
                            name: d.data.scope,
                            description: that.allScopes[d.data.scope]?.description,
                            apps: d.data.apps,
                            category: that.allScopes[d.data.scope]?.category
                        }
                    }
                })
                .on("mousemove", function(e) {
                    const d = d3.select(this).datum().data
                    if (d.scope) {
                        that.tooltipXY = [e.clientX + 10, e.clientY - 15]
                    }
                })
                .on("mouseleave", () => this.tooltipXY = [-1, -1])
            
            rect.attr("width", function() { return this.parentNode.getBBox().width + 20 }) // 10 padding on either side

            // tick function called by the simulation
            simulation.on("tick", () => {
                link
                    .attr("x1", d => d.source.x)
                    .attr("y1", d => d.source.y)
                    .attr("x2", d => d.target.x)
                    .attr("y2", d => d.target.y);

                if (this.zoomed) {
                    node
                        .attr("transform", d => `translate(${d.x},${d.y})`)
                } else {
                    node
                        .attr("cx", d => d.x)
                        .attr("cy", d => d.y);
                }

                category
                    .attr("transform", function(d) {
                        return `translate(${d.x - (this.getBBox().width / 2)},${d.y - 10})`
                    })
            });

            const zoom = d3.zoom()
                .scaleExtent([0.25, 10])
                .on('zoom', handleZoom)

            function initZoom() {
                d3.select('#security-viz')
                    .call(zoom);
            }

            function handleZoom(e) {
                that.zoomLayer.attr('transform', e.transform);
            }

            initZoom()

            d3.select('#security-viz')
                .on('click.background', function(e) {
                    // handle clicking outside nodes
                    if (this == e.target) {
                        that.closeSidePanel()
                    }
                })
        },
        drawScopes(layer, nodes, drag) {
            const that = this
            return layer.append("g")
                    .selectAll("circle")
                    .data(nodes.filter(n => !n.data.toplevel))
                    .join("circle")
                    .attr("cursor", d => d.data.apps ? "pointer" : "cursor")
                    .attr("fill", d => {
                        if (d.data.name == rootName) {
                            return 'black'
                        } else if (!d.data.apps) {
                            return '#ddd'
                        }
                        return that.scopeColor(d.data.scope)
                    })
                    .attr("r", 10)
                    .attr("stroke-opacity", 0)
                    .attr("stroke-width", 0)
                    .attr("stroke", d => {
                        if (d.data.name == rootName) {
                            return 'black'
                        } else if (!d.data.apps) {
                            return '#ddd'
                        }
                        return that.scopeColor(d.data.scope)
                    })
                    .attr("stroke-position", "outside")
                    .on("mouseenter", function() {
                        const node = d3.select(this)
                        const d = node.datum().data
                        if (d.scope) {
                            that.tooltip = {
                                type: "Scope",
                                name: d.scope,
                                description: that.allScopes[d.scope]?.description,
                                apps: d.apps,
                                category: that.allScopes[d.scope]?.category
                            }

                            node.transition()
                                .duration(100)
                                .attr("stroke-width", "8")
                                .attr("stroke-opacity", ".25")
                                .attr("r", 16)
                        }
                    })
                    .on("mousemove", function(e) {
                        const d = d3.select(this).datum().data
                        if (d.scope) {
                            that.tooltipXY = [e.clientX + 20, e.clientY - 15]
                        }
                    })
                    .on("mouseleave", function() {
                        that.tooltipXY = [-1, -1]
                        const node = d3.select(this)
                        node.transition()
                            .duration(100)
                            .attr("stroke-width", "0")
                            .attr("stroke-opacity", "0")
                            .attr("r", 10)
                    })
                    .on("click.zoom", function(e, d) {
                        if (d.data.name == rootName) {
                            that.zoom(rootName)
                        } else if (!d.data.children.length && d.data.parent?.name == rootName) {
                            that.zoom(rootName)
                        } else if (d.data.category) {
                            that.zoom(d.data.category)
                        }

                        if (d.data.apps) {
                            that.highlighted = d.data.name
                        }

                        that.tooltipXY = [-1, -1]
                    })
                    .call(drag);
        },
        drawZoomedScopes(layer, nodes, drag) {
            const that = this

            const group = layer.append("g")
                .selectAll("g")
                .data(nodes)
                .join("g")
                .attr("class", "scope")
                .attr("text-anchor", "middle")
                .attr("dominant-baseline", "middle")
                .attr("cursor", "pointer")
                .on("click", function(e, d) {
                    if (!d.data.apps) {
                        // if they click on a node that has no apps we do nothing
                        return
                    }

                    const currentSelectedScope = that.selectedScopeNode && that.selectedScopeNode.datum().data.scope
                    that.unselect(that)

                    if (currentSelectedScope == d.data.scope) {
                        // clicked on what's already selected, we unselected it and we're done
                        that.showSidePanel = false
                        that.unfade()
                        return
                    } else {
                        that.showSidePanel = true
                    }

                    // fade out the other nodes
                    d3.selectAll("line.link")
                        .attr("opacity", "0.2")
                    d3.selectAll("g.scope > circle")
                        .attr("stroke-opacity", d2 => d.data.scope == d2.data.scope ? "1" : "0.15")
                    d3.selectAll("text.scope")
                        .attr("opacity", d2 => d.data.scope == d2.data.scope ? "1" : "0.15")

                    that.selectedScope = d.data

                    const g = d3.select(this)
                    that.selectedScopeNode = g.select("circle")
                    g.raise()

                    const outerRadius = 130
                    const maxThings = 15
                    const angle = 360 / maxThings
                    const maxCircles = 11
                    const data = Object.values(d.data.apps).sort((a, b) => b.icon.length - a.icon.length).slice(0, maxCircles)
                    
                    const userSet = {}
                    Object.values(d.data.apps).forEach(a => {
                        a.userSet.forEach(u => {
                            userSet[u] = true
                        })
                    })
                    const scopeNumUsers = Object.keys(userSet).length

                    if (data.length != Object.values(d.data.apps).length) {
                        data[maxCircles - 1].overflow = Object.values(d.data.apps).length - maxCircles + 1
                    }

                    const startAngle = angle * (data.length - 1) / 2 * Math.PI / 180
                    data.forEach((d, i) => {
                        d.x = outerRadius * Math.sin(Math.PI + startAngle - (i * angle * Math.PI / 180))
                        d.y = outerRadius * Math.cos(Math.PI + startAngle - (i * angle * Math.PI / 180))
                    })

                    const superG = g.append("g")
                        .attr("transform", "rotate(-45)")
                        .attr("opacity", "0")
                        .attr("class", "app")
                    
                    superG
                        .transition()
                        .duration(500)
                        .attr("transform", "rotate(0)")
                        .attr("opacity", "1")

                    const appG = superG
                        .selectAll("g")
                        .data(data)
                        .join("g")
                        .attr("transform", (d2) => `translate(${d2.x}, ${d2.y})`)
                        .on("click", (e, d) => {
                            e.stopPropagation()
                            that.selectedApplication = d
                            that.securityPanelVisible = true
                        })
                        .on("mouseenter.app", function (e, d) {
                            if (d) {
                                that.tooltip = {
                                    type: "App",
                                    name: d.name,
                                }
                            }
                        })
                        .on("mousemove.app", function (e, d) {
                            if (d) {
                                that.tooltipXY = [e.clientX + 10, e.clientY - 15]
                            }
                        })
                        .on("mouseleave.app", () => that.tooltipXY = [-1, -1])
                        .raise()

                    appG
                        .append("circle")
                        .attr("r", 20)
                        .attr("stroke", () => that.scopeColor(d.data.scope))
                        .attr("stroke-width", "5")
                        .attr("fill", d2 => {
                            return d2.overflow || !d2.icon ? that.scopeColor(d.data.scope) : "#fff"
                        })

                    appG
                        .append("image")
                        .attr("x", "-16")
                        .attr("y", "-16")
                        .attr("width", 32)
                        .attr("height", 32)
                        .attr("opacity", d2 => d2.overflow ? '0' : '1')
                        .attr("xlink:href", d => d.icon)
                        .style('clip-path', 'circle(16px)')

                    appG
                        .append("text")
                        .text(d2 => {
                            if (d2.overflow) {
                                return d2.overflow ? `+${d2.overflow}` : ''
                            } else if (!d2.icon) {
                                // this draws just the first letter when we don't have an icon
                                return d2.name.substring(0, 1).toUpperCase()
                            }
                        })
                        .attr("fill", "#fff")
                        .attr("font-weight", "500")
                    
                    const textarea = g.append("g")
                        .attr("class", "textarea")
                        .attr("text-anchor", "start")

                    const usertext = textarea.append("g")

                    const numusers = usertext.append("text")
                        .attr("font-weight", "600")
                        .text(scopeNumUsers)
                    
                    usertext.append("text")
                        .text("Users")
                        .attr("font-weight", "600")
                        .attr("dy", "1.5em")

                    numusers.attr("transform", `translate(${usertext.node().getBBox().width / 2 - (numusers.node().getBBox().width / 2)})`)
                    const middle = textarea.node().getBBox().width + 10
                    
                    textarea.append("line")
                        .attr("x1", middle)
                        .attr("x2", middle)
                        .attr("y1", -10)
                        .attr("y2", textarea.node().getBBox().height)
                        .attr("stroke", "#ddd")
                        .attr("stroke-width", "2")
                    
                    const appstext = textarea.append("g")

                    const numapps = appstext.append("text")
                        .attr("font-weight", "600")
                        .text(() => Object.keys(d.data.apps).length)

                    appstext.append("text")
                        .text("Apps")
                        .attr("font-weight", "600")
                        .attr("dy", "1.5em")
                    
                    numapps.attr("transform", `translate(${appstext.node().getBBox().width / 2 - (numapps.node().getBBox().width / 2)})`)

                    appstext.attr("transform", `translate(${middle + 10}, 0)`)

                    textarea.attr("transform", `translate(-${textarea.node().getBBox().width / 2},120)`)

                    that.selectedScopeNode.transition()
                        .duration(500)
                        .attr("r", 100)
                })
            
            group
                .append("circle")
                .attr("fill", "#fff")
                .attr("stroke", function(d) {
                    if (d.data.name == rootName) {
                        return 'black'
                    } else if (!d.data.apps) {
                        return '#ddd'
                    }
                    return that.scopeColor(d.data.scope)
                })
                .attr("stroke-width", "10")
                .attr("r", 50)
                .call(drag);
            
            group
                .append("text")
                .attr("class", "scope")
                .text(d => d.data.name)

            // this will simulate a click on a scope that the user clicked previously to get 
            // more details (ie: not a category node) so they don't end up having to click twice to see what they wanted
            if (that.highlighted) {
                const n = d3.selectAll("g.scope")
                n.nodes().forEach(node => {
                    const s = d3.select(node)
                    if (s.datum().data.name == that.highlighted) {
                        s.dispatch("click")
                    }
                })
                that.highlighted = '' // so we don't reuse this on the next go
            }

            return group
        },
        unselect(that) {
            if (that.selectedScopeNode) {
                d3.select(that.selectedScopeNode.node().parentNode)
                    .selectAll("g.app")
                    .transition()
                    .duration(300)
                    .attr("transform", "rotate(-45)")
                    .attr("opacity", "0")
                    .remove()
                d3.select(that.selectedScopeNode.node().parentNode)
                    .selectAll("g.textarea")
                    .remove()

                that.selectedScopeNode.transition().duration(300).attr("r", "50")

                that.selectedScopeNode = null
            }
        },
        unfade() {
            // unfade everything
            d3.selectAll("line.link")
                .attr("opacity", "1")
            d3.selectAll("g.scope > circle")
                .attr("stroke-opacity", "1")
            d3.selectAll("text.scope")
                .transition().duration(300)
                .attr("opacity", "1")
        },
        searchFiltered(apps = {}) {
            const filteredNames = Object.keys(apps).filter(name => name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()))

            const searchedApps = {}
            for (const name of filteredNames) {
                searchedApps[name] = apps[name]
            }

            return searchedApps
        }
    }
}
</script>
