<template>
    <v-img v-if="logoUrl" id="brand-logo" :max-width="width" :max-height="height" :src="logoUrl" contain v-on:error="failedToLoadBrandLogo = true" :position="position"/>
    <YeshIDLogo v-bind="$props" v-else :black="$vuetify.theme.options.darkNav"/>
</template>

<script>
import YeshIDLogo from "@/components/YeshIDLogo.vue"
import {mapGetters} from "vuex"

export default {
    components: {
        YeshIDLogo
    },
    extends: YeshIDLogo,
    props: {
        orgId: {
            type: String,
        },
    },
    data() {
        return {
            failedToLoadBrandLogo: false,
        }
    },
    computed: {
        ...mapGetters({
            orgSettings: "orgSettings",
            isAdmin: "isAdmin",
        }),
        logoUrl() {
            if (this.failedToLoadBrandLogo || this.isAdmin) {
                return null
            }

            const logoUrl = this.orgSettings?.logoUrl
            if (logoUrl) {
                return `${logoUrl}?${Date.now()}`
            }

            if (this.orgId) {
                const hostname = window.location.hostname
                let env = "production"
                if (hostname === "localhost" || hostname.endsWith(".dev.yesh.id")) {
                    env = "dev"
                } else if (hostname.endsWith(".staging.yesh.id")) {
                    env = "staging"
                }
                return `https://storage.googleapis.com/yeshid-app-${env}-assets/org/${this.orgId}/logo?${Date.now()}`
            }

            return null
        }
    },
    watch: {
        "orgSettings.logoUrl": function() {
            this.failedToLoadBrandLogo = false
        },
    },
}
</script>