import axios from 'axios'
import posthog from 'posthog-js'

const state = {
    user: null,
    org: null,
    orgSettings: {},
    gwsOauthConnected: null,
    gwsSamlConnected: null,
    gwsUpdateStatus: "",
    featureFlags: {},
    isOnboarded: false,
    isMigratedToDirectory: false,
};

const getUserRoles = state => state.user && state.user.roles

const getters = {
    isAuthenticated: state => !!state.user,    
    isAdmin: state => state.user? state.user.type === "ADMIN" : false,
    isBackOfficeAdmin: state => state.user? state.user.isBackOfficeAdmin: false,
    StateUser: state => state.user,
    Org: state => state.org,
    EmailDomains: state => state.org && state.org.domains ? state.org.domains : [state.user.email.split('@')[1]],
    gwsOauthConnected: state => !!state.gwsOauthConnected,
    gwsSamlConnected: state => !!state.gwsSamlConnected,
    gwsUpdateStatus: state => state.gwsUpdateStatus,
    isOnboarded: state => state.isOnboarded, // see how this impacts routing in /router/index.js
    isMigratedToDirectory: state => state.isMigratedToDirectory,
    hasRole: state => id => {
        const roles = getUserRoles(state)
        if (roles) {
            return roles.includes(id)
        }
        return false
    },
    userRoles: state => getUserRoles(state) || [],
    checkFeatureFlag: state => featureFlag => state.featureFlags && !!state.featureFlags[featureFlag],
    getOrgSetting: state => setting => state.orgSettings ? state.orgSettings[setting] : undefined,
    orgSettings: state => state.orgSettings,
};

const actions = {
    async Login({commit, dispatch}, user) {
        await commit("setUser", user);

        let org
        await axios.get(`/api/v1/org/${user.orgId}`).then(async response => {
            await commit('setOrg', response.data)
            org = response.data
        }).catch(error => {
            console.log(error)
        })

        await commit('setIsMigratedToDirectory', org.isMigratedToDirectory)
        if (org.isMigratedToDirectory) {
            // so we don't get redirected and error banners
            await commit('setOnboarded', true)
            await commit('setGwsOauthStatus', true)
        } else {
            await axios.get('/api/v1/integrations/google/status').then(async response => {
                await commit('setGwsOauthStatus', response.data.isOauthConnected)
                await commit('setGwsSamlStatus', response.data.isSamlConnected)
                await commit('setGwsUpdateStatus', response.data.updateStatus)
                // if the lastUpdated time is set, isOnboarded should be true
                if (response.data.lastUpdated.length > 0) {
                    await commit('setOnboarded', true)
                }

            }).catch(error => {
                console.log(error)
            })
        }

        try {
            if (posthog) {
                posthog.identify(user.id, { email: user.email, name: `${user.name} ${user.lastName}` })
                const domain = user.email.split('@')[1]
                const orgId = user.orgId

                // posthog only allows filtering feature flags by the properties of groups or people not the actual key of the group or person
                // so we also send the orgId as a property of the org itself to feature flag specific orgs as an org could have multiple domains
                posthog.group('organization', orgId, { orgId, domain })
            }
        } catch(err) {
            console.log('posthog login error', err)
        }

        await dispatch('fetchFeatureFlags')
        await dispatch('fetchOrgSettings')
    },
    async Logout({ commit }) {
        let user = null;
        commit("Logout", user);

        // This will unlink any future events made on their current device with the currently logged in user
        // Not calling reset() means that future events on this device without logging will be recorded with
        // the previously logged in user
        // try {
        //     if (posthog) {
        //         posthog.reset()
        //     }
        // } catch(err) {
        //     console.log('posthog logout error', err)
        // }
    },
    async updateGwsOauthStatus({commit}, oauth) {
        await commit("setGwsOauthStatus", oauth)
    },
    async updateGwsSamlStatus({commit}, saml) {
        await commit("setGwsSamlStatus", saml)
    },
    async updateGwsUpdateStatus({commit}, update) {
        await commit("setGwsUpdateStatus", update)
    },
    async updateOnboarded({commit}, isOnboarded) {
        await commit("setOnboarded", isOnboarded)
    },
    async updateIsMigratedToDirectory({commit}, isMigratedToDirectory) {
        await commit("setIsMigratedToDirectory", isMigratedToDirectory)
    },
    async fetchFeatureFlags({commit, getters}) {
        const user = getters.StateUser
        await axios.get(`/api/v1/org/${user.orgId}/featureflags`).then(async response => {
            await commit('setFeatureFlags', { featureFlags: response.data })
        }).catch(error => {
            console.log(error)
        })
    },
    async fetchOrg({commit, getters}) {
        const user = getters.StateUser
        await axios.get(`/api/v1/org/${user.orgId}`).then(async response => {
            await commit('setOrg', response.data)
        }).catch(error => {
            console.log(error)
        })
    },
    async fetchOrgSettings({commit, getters}) {
        const user = getters.StateUser
        await axios.get(`/api/v1/org/${user.orgId}/settings`).then(async response => {
            await commit('setOrgSettings', { orgSettings: response.data })
        }).catch(error => {
            console.log(error)
        })
    },
    async updateOrgSettings({commit, getters}, settings) {
        const user = getters.StateUser
        const orgSettings = getters.orgSettings
        await axios.put(`/api/v1/org/${user.orgId}/settings`, { ...orgSettings, ...settings }).then(async response => {
            await commit("setOrgSettings", { orgSettings: response.data })
        }).catch(error => {
            console.log(error)
        })
    },
};

const mutations = {
    setUser(state, user) {
        state.user = user
    },
    setOrg(state, org) {
        state.org = org
    },
    Logout(state) {
        state.user = null
        state.org = null
        state.isOnboarded = false
    },
    setGwsOauthStatus(state, oauth) {
        state.gwsOauthConnected = oauth
    },
    setGwsSamlStatus(state, saml) {
        state.gwsSamlConnected = saml
    },
    setGwsUpdateStatus(state, update) {
        state.gwsUpdateStatus = update
    },
    setOnboarded(state, isOnboarded) {
        state.isOnboarded = isOnboarded
    },
    setIsMigratedToDirectory(state, isMigrated) {
        state.isMigratedToDirectory = isMigrated
    },
    setFeatureFlags(state, { featureFlags = {} }) {
        state.featureFlags = featureFlags
    },
    setOrgSettings(state, { orgSettings = {} }) {
        state.orgSettings = orgSettings
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
